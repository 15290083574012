<template>
  <v-container fill-height fluid
    id="Promotion"
    tag="section"
  >
    <v-row align="center"
        justify="center">
        <v-col cols="12" align="center" class="ma-0 pa-0">
            <v-card flat max-width="820">
                <v-row align="start">
                    <v-col align="start" cols="12" class="pa-0 ma-1">
                      <span class="capture"><router-link to="/Promotions">{{ $t('MeeTeeMeeNgern1.promotions') }}</router-link> > </span>
                      <span class="capture gray--text">{{ propValue.promotions_name }}</span>
                    </v-col>
                    <v-col align="start" class="ma-0 pa-0">
                        <v-card flat class="ma-0 pa-0">
                            <img v-bind:src="utils.imageWait(propValue.picture_base64)" width="100%" height="100%">
                            <base-subheading><span class="headline">{{ propValue.promotions_name }}</span></base-subheading>
                            <v-card-text class="display-1" v-html="propValue.promotions_detail">
                            </v-card-text>
                            <!-- <v-card-text>
                                <span class="display-1">{{ propValue.promotions_detail }}</span> 
                            </v-card-text> -->
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'FeedCard',

    props: {
      size: {
        type: Number,
        required: true,
      },
      propValue: {
        type: Object,
        default: () => ({}),
      },
    },
    /* Golf 2022-9-1 */
    mounted () {
      window.scrollTo(0, 0)
    },
    computed: {
      items () {
        return [
          {
            text: this.$t('MeeTeeMeeNgern1.Promotions'),
            disabled: false,
            href: 'Promotions',
          },
          {
            text: this.propValue.promotions_name,
            disabled: true,
            href: 'Promotion',
          },
        ]
      },
      currentRouteName () { /* 3 visit count menu */
        return this.$route.name
      },
    },
    data () {
      return {
        utils: require('@/utils'),
        stringFormat: require('string-format'),
      }
    },
    created: function () {
      console.log(this.propValue, 'this.propValue')
      this.utils.UtilsAddVisitCountMenu(this.currentRouteName, this.propValue.skey) /* 1 visit count menu */
    },
    methods: {
      async selectLanguage (language) {
        await this.retrieveFrontPromotions(language)
      },
      async retrieveFrontPromotions (languageID) {
        console.log('retrieveFrontPromotions', 'response.data.data')
        var aLimitShow = this.limitShow
        if (!aLimitShow) { aLimitShow = 0 }
        await this.axios.post(process.env.VUE_APP_API + '/promotions/retrieveFrontPromotions' + process.env.VUE_APP_DATABASE_TYPE, {
          limit: aLimitShow,
          language_id: languageID,
        })
          .then(response => {
            if (response.data.isSuccess) {
              /* console.log(response.data.data.filter(el => {
                return el['skey'] === this.propValue.skey
              }), '11111')
              console.log(response.data.data.find(el => {
                return el['skey'] === this.propValue.skey
              }), '11111') */
              console.log(response.data.data, 'response.data.data')
              this.propValue = response.data.data.find(el => {
                return el['skey'] === this.propValue.skey
              })
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
    }
  }
</script>

<style>
.v-image__image {
  transition: .3s linear;
}
</style>
